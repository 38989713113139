<template>
  <div class="supplierCommodityManagement-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  let validCode = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("商品编号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validItemsName = (rule, value, callback) => {
      if (value == "" || value == undefined) {
          callback();
      } else {
          //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
          let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
          if (!reg.test(value)) {
              callback(new Error("商品名称格式不对或超过32个字符"));
          } else {
              callback();
          }
      }
  };
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "商品管理",
          search: [{
                key: "merchantId",
                label: "发布商",
                type: 'remoteSearchById',
                opt: {
                    list: []
                }
            },{
              key: "typeName",
              label: "商品品类",
            },
            {
              key: "name",
              label: "商品名称",
              maxlength: 64,
              rules: [{
                  validator: validItemsName,
                  trigger: ['blur']
              }]
            },
            {
              key: "code",
              label: "商品编号",
              rules: [{
                validator: validCode,
                trigger: ['blur']
              }]
            }
          ],
          columns: [{
              label: "商品名称",
              key: "name"
            },
            {
              label: "商品编号",
              key: "code"
            },
            {
              label: "商品品类",
              key: "typeName"
            },
            {
              label: "成本价",
              key: "costPriceVal"
            },
            {
              label: "经销价",
              key: "supplyPriceVal"
            },
            {
              label: "销售价",
              key: "sellPriceVal"
            },
            {
              label: "发布商",
              key: "merchantName"
            },
            {
              label: "商品详细描述",
              key: "detail"
            },
            {
              label: "商品图片",
              key: "pic",
              type: "img-shop"
            },
            {
              label: "上架时间",
              key: "updatedTime",
            }
          ]
        },
      };
    },
    created() {
      console.log("supplierCommodityManagement-list created!!");

    },
    activated() {
      console.log("supplierCommodityManagement-list activated!!");
      let merchantFlag = this.getLoginType('loginType');
      if(merchantFlag) {
        this.opt.columns.forEach((item,index) => {
          if(item.key == 'action') {
            this.opt.columns.splice(index,1);
          }
        })
      }
      this.getMerchantList()
    },
    mounted() {
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("mall-service/items/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
            res.data.list.forEach(item => {
              item.costPriceVal = item.costPrice == null ? '--' : item.costPrice.toFixed(2);
              item.sellPriceVal = item.sellPrice == null ? '--' : item.sellPrice.toFixed(2);
              item.supplyPriceVal = item.supplyPrice == null ? '--' : item.supplyPrice.toFixed(2);
              item.detail = item.detail ? item.detail : "--";
              if (item.detail.length > 17) {
                item.detail = item.detail.substr(0, 17) + '...';
              }
              if (item.name.length > 17) {
                item.name = item.name.substr(0, 17) + '...';
              }
            })
            opt.cb(res.data);

        });
      },
      // 获取商家列表
      getMerchantList() {
        this.post('/mall-service/merchant/v1/page', {
            pageNumber: 1,
            pageSize: 9999,
            roleTypeList: [1,3]
        }).then(res => {
            var array = res.data;
            array.forEach(item => {
                item.value = item.id;
                if(item.roleType == 1){
                  item.label = item.name + '（' + '商家' + '）';
                } else{
                  item.label = item.name + '（' + '经销商' + '）';
                }
            })
            console.log(array);
            this.opt.search.forEach(item => {    
                if(item.key == 'merchantId') {
                item.opt.list = array
                }
            })
        })
    },
    }
  };
</script>
<style lang="scss" scope>
</style>